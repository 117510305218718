import { configureStore } from '@reduxjs/toolkit';
import tokenReducer from './tokenSlice';
import userReducer from './userSlice';

const store = configureStore({
    reducer: {
        token: tokenReducer, // Top-level token state
        user: userReducer,   // Top-level user state
        // Add more reducers for other states if needed
    },
});

export default store;
