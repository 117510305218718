/**
 * @file WorkflowLibrary.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component acts as a central hub for managing and navigating workflows in the application. It includes a dynamic sidebar with a toggle logo, allowing users to quickly access various workflow libraries. The main content section provides sorting, searching, and favoriting options for user-specific workflows.
 *<br/>Breakdown of the code - {@tutorial WorkflowLibrary}
 * @module WorkflowLibrary
 *
 **/

import React from "react";
import logo from "../../assets/img/workflow_lib/mediMESH-Icon.svg";
import WorkflowLibraryNav from "./WorkflowLibraryNav";
import SearchField from "../Misc/SearchField";
import WorkflowLibraryItemList from "./WorkflowLibraryItemList";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions, ElementLayoutHelpers } from 'helper-functions-package';
import userAPI from "../../api/userAPI";
import FlipMove from "react-flip-move";
import VideoService from "../../services/VideoService";
import segmentAPI from "../../api/segmentAPI";
import { Spinner } from "flowbite-react";
import authAPI from "../../api/authAPI";
import { getAuthAccessObject } from "../../utils/tokenStorage";
import { ToastContainer } from "react-toastify";

import workflowlibraryAPI from "../../api/workflowlibraryAPI";
import WorkflowLibraryService from "../../services/WorkflowLibraryService";
import SettingsContainer from "../Settings/SettingsContainer";
import ActionsMenuCustom from "../Misc/ActionsMenuCustom";
import { withTranslation } from "react-i18next";
import Workflow from "../../services/WorkflowService";
import CheckLogin from "../CheckLogin";
import store from "../../store/store";

class WorkflowLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOptions: [
        { label: props.t("general.name_a_z"), tag: "az" },
        { label: props.t("general.name_z_a"), tag: "za" },
        { label: props.t("general.by_date"), tag: "date" },
      ],
      langOptions: [
        { label: "English", tag: "en", icon: "drop-down-menu-icon-eng" },
        { label: "German", tag: "de", icon: "drop-down-menu-icon-de" },
        { label: "Japanese", tag: "ja", icon: "drop-down-menu-icon-jp" },
      ],
      keywords: "",
      keywordsFavorite: "",
      workflowList: [],
      favoriteWorkflowList: [],
      isSidebarOpen: true,
      itemClicked: false,
      userObject: null,
      itemLoading: true,
      activeItem: null,
      Update: false,
      settings: false,
      userMainObject: null,
      workflowHeading: null,
      favItemLoading: true,
      user: null
    };

    this.favoriteContentSectionRef = React.createRef();
  }

  selectedWorkflowLibrary = (item) => {
    this.setState({ activeItem: item });
  };

  includePhasesToVideo(workflow) {
    workflow.getVideos.map(async (video) => {
      let videoObj = new VideoService(await video);
      let getPhases = segmentAPI.getSegmentTypeJsonByID(
        videoObj.getVideoID,
        "PhaseDto"
      );

      return getPhases.then((resp) => {
        try {
          let segments = JSON.parse(resp);
          let phases = segments.filter((seg) => seg.level === "phase");
          return (video.phases = phases);
        } catch (e) {
          return (video.phases = null);
        }
      });
    });
    return workflow;
  }

  componentDidMount() {
    const loginObject = getAuthAccessObject();
    if (MiscFunctions.isNull(loginObject)) authAPI.logout();
    ElementLayoutHelpers.horizontalScroll(this.favoriteContentSectionRef);
    this.loadFavoritedWorkflows();
  }

  loadFavoritedWorkflows() {
    this.setState({ favItemLoading: true, favoriteWorkflowList: [] });
    userAPI.getUserFavoritedWorkflows().then((res) => {
      let favoriteWorkflowList = [];
      if (res) {
        res.map((el) => {
          const favItem = new Workflow(el);
          favoriteWorkflowList.push(this.includePhasesToVideo(favItem));
        });
      }
      this.setState({
        favoriteWorkflowList: favoriteWorkflowList,
        favItemLoading: false,
      });
    });

    this.userUpdate()
  }

  componentDidUpdate(prevProps, prevStats) {
    if (prevStats.keywords !== this.state.keywords) {
      this.setState({ keywords: this.state.keywords });
    }
    if (prevStats.keywordsFavorite !== this.state.keywordsFavorite) {
      this.setState({ keywordsFavorite: this.state.keywordsFavorite });
    }
    if (prevStats.workflowList !== this.state.workflowList) {
      this.setState({ workflowList: this.state.workflowList });
    }
    if (JSON.stringify(prevStats.userObject) !== JSON.stringify(this.state.userObject)) {
      this.userUpdate();
    }

    if (prevStats.Update !== this.state.Update) {
      this.loadFavoritedWorkflows();
    }

    if (
      !MiscFunctions.isNull(this.state.activeItem) &&
      prevStats.activeItem !== this.state.activeItem
    ) {
      const acItem = new WorkflowLibraryService(this.state.activeItem);

      this.setState({
        workflowHeading: acItem.getLabel,
        itemLoading: true,
        workflowList: [],
      });
      workflowlibraryAPI
        .getWorkflowLibraryById(acItem.getWorkflowLibraryID)
        .then((response) => {
          let workflows = MiscFunctionsLocal.sortDataByLabel(response, "az");
          workflows.map((workflow) => {
            return this.includePhasesToVideo(workflow);
          });
          // this.loadFavoritedWorkflows();
          this.setState({
            workflowList: workflows,
            itemLoading: false,
          });
        });
    }

  }

  userUpdate = async () => {
    userAPI.getUserObject().then((user) => {
      store.dispatch({ type: 'user/setUser', payload: user })
      if (user !== false && !MiscFunctions.isNull(user)) {
        this.setState({ userMainObject: user });
        let User = MiscFunctionsLocal.createUserObject(user);
        this.setState({ userObject: User });
      }
    })

  }

  hotRefreshUserObject = (object) => {
    this.setState({ userObject: MiscFunctionsLocal.createUserObject(object) });
  };

  setKeywords(input, workflowObject) {
    if (workflowObject === "all") {
      this.setState({ keywords: input });
    } else if (workflowObject === "favorite") {
      this.setState({ keywordsFavorite: input });
    }
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  handleSortOptionChange = (selectedOption, workflowObject) => {
    if (workflowObject === "all") {
      let sortedData = MiscFunctionsLocal.sortDataByLabel(
        this.state.workflowList,
        selectedOption
      );
      this.setState({ workflowList: sortedData });
    } else if (workflowObject === "favorite") {
      let sortedData = MiscFunctionsLocal.sortDataByLabel(
        this.state.favoriteWorkflowList,
        selectedOption
      );
      this.setState({ favoriteWorkflowList: sortedData });
    }
  };

  handleFavoriteWorkflow(item, isFavorite) {
    if (isFavorite === true) {
      userAPI
        .deleteFavouriteWorkflowByID(item.getWorkflowID)
        .then(async (res) => {
          this.setState({ Update: !this.state.Update });
        });
    } else if (isFavorite === false) {
      const isDuplicate = this.state.favoriteWorkflowList.some(
        (existingItem) => {
          return existingItem.getWorkflowID === item.getWorkflowID;
        }
      );

      userAPI.favouriteWorkflowByID(item.getWorkflowID).then(async (res) => {
        this.setState({ Update: !this.state.Update });
      });
    }
  }

  searchFilter(workflowList, keywords) {
    return workflowList.filter((item) =>
      item.label.toLowerCase().includes(keywords.toLowerCase())
    );
  }

  handleSettings = (status) => {
    this.setState({ settings: status });
  };

  render() {
    const { isSidebarOpen } = this.state;
    const buttonRotation = isSidebarOpen ? "0deg" : "360deg";
    const contentLogo = isSidebarOpen ? "hidden" : "visible";

    let { workflowList, favoriteWorkflowList, keywords, keywordsFavorite } =
      this.state;

    workflowList = this.searchFilter(workflowList, keywords);
    favoriteWorkflowList = this.searchFilter(
      favoriteWorkflowList,
      keywordsFavorite
    );
    let userRole = false;
    if (!MiscFunctions.isNull(this.state.userObject)) {
      userRole =
        this.state.userObject.role.toLowerCase() === "admin" ? true : false;
    }

    return (
      <>
        <CheckLogin />
        <ToastContainer />
        <main className="workflow-library-main-container">
          <section
            className={`workflow-library-sidebar ${isSidebarOpen ? "open" : "closed"
              }`}
          >
            <div
              className={`workflow-library-logo-block ${isSidebarOpen
                ? "workflow-library-logo-block-open"
                : " workflow-library-logo-block-closed"
                }`}
              onClick={this.toggleSidebar}
            >
              <img
                src={logo}
                className="workflow-library-logo"
                alt="mediMESH Logo"
                style={{ transform: `rotate(${buttonRotation})` }}
              />
            </div>
            <WorkflowLibraryNav
              selectedWorkflowLibrary={this.selectedWorkflowLibrary}
              userObject={this.state.userObject}
              settings={this.state.settings}
              handleSettings={this.handleSettings}
            />

            <div className="workflow-library-side-footer">
              <hr />
              {userRole && (
                <nav>
                  <li
                    className={`workflow-library-nav-item-block ${this.state.settings ? "active" : ""
                      }`}
                    onClick={() => {
                      this.handleSettings(true);
                      this.selectedWorkflowLibrary(null);
                    }}
                  >
                    <span
                      className={`workflow-library-item-list-setting-icon ${this.state.settings ? "active" : ""
                        }`}
                    ></span>
                    <span className="setting-menu-headline">
                      {this.props.t("general.settings")}
                    </span>
                  </li>
                </nav>
              )}
              <div className="workflow-library-btngroup">
                <button className="workflow-library-logout-btn">
                  <span className="workflow-library-logout-btn-icon"></span>
                  <span
                    className="workflow-library-logout-btn-text"
                    onClick={() => {
                      authAPI.logout();
                      localStorage.removeItem("activeWorkflowId");
                    }}
                  >
                    {this.props.t("general.logout")}
                  </span>
                </button>
                <a
                  href="mailto:support@medimesh.de"
                  role="button"
                  className="workflow-library-getsupport-btn"
                >
                  <span className="workflow-library-getsupport-btn-icon"></span>
                  <span className="workflow-library-getsupport-btn-text">
                    {this.props.t("general.get_support")}
                  </span>
                </a>
              </div>
              <hr className="workflow-library-line" />
              <span className="workflow-library-text">
                <a href="https://medimesh.de/nutzungsbedingungen/" target="_blank" rel="noopener noreferrer"> {this.props.t("messages.terms_of_use")}</a> {this.props.t("messages.and")}
                <a href="https://medimesh.de/datenschutz/" target="_blank" rel="noopener noreferrer"> {this.props.t("messages.privacy_policy")}</a>
              </span>
              <span className="workflow-library-version-text">
                mediMESH App V.1.0
              </span>
            </div>
          </section>

          <div
            className={`workflow-library-content-section ${isSidebarOpen ? "" : "sideb-closed"
              }`}
            style={
              this.state.settings ? { paddingLeft: 0 } : { paddingLeft: "20px" }
            }
          >
            <div
              className={`workflow-library-header ${isSidebarOpen ? "" : "workflow-library-header-full"
                }`}
            >
              <div
                className="workflow-library-logo-block"
                style={{
                  visibility: `${contentLogo}`,
                }}
              >
                <img
                  src={logo}
                  className="workflow-library-logo"
                  alt="mediMESH Logo"
                  onClick={this.toggleSidebar}
                  style={{
                    transform: `rotate(${buttonRotation})`,
                  }}
                />
              </div>
              <span
                className="workflow-library-page-heading"
                style={
                  this.state.settings
                    ? { paddingLeft: "21px" }
                    : { paddingLeft: "0" }
                }
              >
                <span className="workflow-library-page-heading-icon"></span>
                {this.state.settings
                  ? this.props.t("general.settings")
                  : this.state.workflowHeading}
              </span>
              <div className="workflow-library-user-profile">
                <div
                  className="workflow-library-user-profile-pic"
                  style={{
                    background: `rgb(97 97 97 / 9%) url(${!MiscFunctions.isNull(this.state.userObject)
                      ? this.state.userObject.avatar
                      : null
                      })`,
                  }}
                ></div>
                <span className="workflow-library-user-details">
                  {!MiscFunctions.isNull(this.state.userObject)
                    ? this.state.userObject.display
                    : "User's Name"}
                </span>
                <ActionsMenuCustom
                  options={this.state.langOptions}
                  preSelection={
                    !MiscFunctions.isUndefined(localStorage.selected_language)
                      ? JSON.parse(localStorage.selected_language)
                      : this.state.langOptions[0]
                  }
                  onClick={(e) => {
                    MiscFunctionsLocal.changeLanguage(e);
                    this.props.i18n.changeLanguage(e.tag);
                    localStorage.setItem("selected_language", JSON.stringify(e));
                    this.userUpdate();
                  }}
                  dropDownSize={"m"} //s, m, l
                  optionIcon={true} // true will load icons from the options and override the dropDownStaticIcon.
                  optionLabel={false}
                  dropDownStaticIcon={false} //icon class e.g drop-down-menu-icon-sort, false to no icon.
                  dropDownIconBG={"primary"} //condence-dark, condenced-light, primary, white, transparent.
                  dropDownShadow={true}
                  dropDownBG={"primary"} //condenced-dark, condenced-light, primary, white, transparent.
                  dropDownRadius={2}
                  dropDownBoxBG={"primary"} //condenced-dark, condenced-light, primary, white, transparent.
                  dropDownBoxshadow={true}
                  dropDownBoxRadius={0}
                  dropDownArrow={true}
                  dropDownSelectedTextColor={"condenced-dark"} //condenced-dark, condenced-light, primary, white, transparent.
                  justifyContent={"center"} //left, right, center
                />
              </div>
            </div>

            {!this.state.settings && (
              <>
                <div className="workflow-library-favorite-section workflow_library_transition">
                  <div className="workflow-library-favorite-header-section">
                    <div className="workflow-library-header-section-title-block">
                      <span className="workflow-library-header-section-title-icon workflow-library-header-section-title-icon-star"></span>
                      <span className="workflow-library-header-section-title-headline">
                        {this.props.t("workflow_lib.my_favorite_workflows")}
                      </span>
                    </div>

                    <div className="workflow-library-header-section-settings">
                      {/* <button className="workflow-library-header-section-fav-btn">
                    <span className="workflow-library-header-section-fav-icon"></span>
                    <span className="workflow-library-header-fav-text">
                      Favorite
                    </span>
                  </button> */}

                      {/* <ActionsMenu
                        options={this.state.sortOptions}
                        onClick={(e) => {
                          this.handleSortOptionChange(e.tag, "favorite");
                        }}
                        icon={"drop-down-menu-icon-sort"}
                        preSelection={this.state.sortOptions[0]}
                      /> */}

                      <ActionsMenuCustom
                        options={this.state.sortOptions}
                        preSelection={this.state.sortOptions[0]}
                        onClick={(e) => {
                          this.handleSortOptionChange(e.tag, "favorite");
                        }}
                        dropDownSize={"m"} //s, m, l
                        optionIcon={false} // true will load icons from the options and override the dropDownStaticIcon.
                        optionLabel={true}
                        dropDownStaticIcon={"drop-down-menu-icon-sort"} //icon class e.g drop-down-menu-icon-sort, false to no icon.
                        dropDownIconBG={"condenced-dark"} //condenced-dark, condenced-light, primary, white, transparent.
                        dropDownShadow={false}
                        dropDownBG={"condenced-light"} //condenced-dark, condenced-light, primary, white, transparent.
                        dropDownRadius={2}
                        dropDownBoxBG={"condenced-light"} //condenced-dark, condenced-light, primary, white, transparent.
                        dropDownBoxshadow={false}
                        dropDownBoxRadius={2}
                        dropDownArrow={true}
                        dropDownSelectedTextColor={"black"} //condenced-dark, condenced-light, primary, white, transparent.
                      />

                      {/* <ActionsMenu
                    options={this.state.categoryOptions}
                    onClick={(e) => {
                      console.log(e);
                    }}
                    icon={"drop-down-menu-icon-category"}
                    preSelection={this.state.categoryOptions[0]}
                  /> */}
                      <div
                        className="workflow-library-header-section-search"
                        style={{ paddingRight: "10px" }}
                      >
                        <SearchField
                          stateHandler={(input) => {
                            this.setKeywords(input, "favorite");
                          }}
                          iconDirection={"left"}
                        />
                      </div>

                      {/* <span className="workflow-library-header-section-bin-icon"></span> */}
                    </div>
                  </div>
                  <div
                    className="workflow-library-favorit-content-section"
                    ref={this.favoriteContentSectionRef}
                  >
                    {this.state.favItemLoading && (
                      <Spinner
                        style={{ height: "50px", marginRight: "5px" }}
                        color="success"
                        aria-label="loading items"
                        size="xl"
                      />
                    )}
                    <FlipMove
                      staggerDurationBy="30"
                      duration={300}
                      style={{ display: "flex" }}
                    >
                      {!this.state.favItemLoading &&
                        favoriteWorkflowList < 1 && (
                          <div className="workflow-library-no-favorit-text">
                            {this.props.t("messages.no_favorited_workflows")}!
                          </div>
                        )}
                      {!MiscFunctions.isNull(favoriteWorkflowList) &&
                        favoriteWorkflowList.map((favoritItem, i) => {
                          return (
                            <WorkflowLibraryItemList
                              workflowList={favoriteWorkflowList}
                              element={favoritItem}
                              onClick={() => {
                                this.setState({ itemClicked: favoritItem });
                              }}
                              itemClicked={
                                this.state.itemClicked === favoritItem
                                  ? "workflow-library-workflow-item-clicked"
                                  : ""
                              }
                              key={i}
                              handleFavoriteWorkflow={(item, isFavorite) =>
                                this.handleFavoriteWorkflow(item, isFavorite)
                              }
                              favoritItem={true}
                            />
                          );
                        })}
                    </FlipMove>
                  </div>
                </div>

                <div className="workflow-library-all-section workflow_library_transition">
                  <div className="workflow-library-all-header-section">
                    <div className="workflow-library-header-section-title-block">
                      <span className="workflow-library-header-section-title-icon workflow-library-header-section-title-icon-workflow"></span>
                      <span className="workflow-library-header-section-title-headline">
                        {this.props.t("workflow_lib.workflow_library")}
                      </span>
                    </div>

                    <div className="workflow-library-header-section-settings">
                      {/* <button className="workflow-library-header-section-fav-btn">
                    <span className="workflow-library-header-section-fav-icon"></span>
                    <span className="workflow-library-header-fav-text">
                      Favorite
                    </span>
                  </button> */}

                      {/* <ActionsMenu
                        options={this.state.sortOptions}
                        onClick={(e) => {
                          this.handleSortOptionChange(e.tag, "all");
                        }}
                        icon={"drop-down-menu-icon-sort"}
                        preSelection={this.state.sortOptions[0]}
                      /> */}

                      <ActionsMenuCustom
                        options={this.state.sortOptions}
                        preSelection={this.state.sortOptions[0]}
                        onClick={(e) => {
                          this.handleSortOptionChange(e.tag, "all");
                        }}
                        dropDownSize={"m"} //s, m, l
                        optionIcon={false} // true will load icons from the options and override the dropDownStaticIcon.
                        optionLabel={true}
                        dropDownStaticIcon={"drop-down-menu-icon-sort"} //icon class e.g drop-down-menu-icon-sort, false to no icon.
                        dropDownIconBG={"condenced-dark"} //condenced-dark, condenced-light, primary, white, transparent.
                        dropDownShadow={false}
                        dropDownBG={"condenced-light"} //condenced-dark, condenced-light, primary, white, transparent.
                        dropDownRadius={2}
                        dropDownBoxBG={"condenced-light"} //condenced-dark, condenced-light, primary, white, transparent.
                        dropDownBoxshadow={false}
                        dropDownBoxRadius={2}
                        dropDownArrow={true}
                        dropDownSelectedTextColor={"black"} //condenced-dark, condenced-light, primary, white, transparent.
                      />
                      {/* <ActionsMenu
                    options={this.state.categoryOptions}
                    onClick={(e) => {
                      console.log(e);
                    }}
                    icon={"drop-down-menu-icon-category"}
                    preSelection={this.state.categoryOptions[0]}
                  /> */}
                      <div
                        className="workflow-library-header-section-search"
                        style={{ paddingRight: "10px" }}
                      >
                        <SearchField
                          stateHandler={(input) => {
                            this.setKeywords(input, "all");
                          }}
                          iconDirection={"left"}
                        />
                      </div>
                    </div>
                  </div>
                  <>
                    {this.state.itemLoading && (
                      <Spinner
                        style={{ height: "50px", marginRight: "5px" }}
                        color="success"
                        aria-label="loading items"
                        size="xl"
                      />
                    )}
                    <FlipMove
                      className="workflow-library-all-content-section"
                      staggerDurationBy="30"
                      duration={300}
                    >
                      {!MiscFunctions.isNull(workflowList) &&
                        workflowList.map((item, i) => {
                          return (
                            <WorkflowLibraryItemList
                              workflowList={workflowList}
                              element={item}
                              onClick={() => {
                                this.setState({ itemClicked: item });
                              }}
                              itemClicked={
                                this.state.itemClicked === item
                                  ? "workflow-library-workflow-item-clicked"
                                  : ""
                              }
                              handleFavoriteWorkflow={(item, isFavorite) =>
                                this.handleFavoriteWorkflow(item, isFavorite)
                              }
                              key={i}
                              favoritItem={
                                !MiscFunctions.isUndefined(this.state.favoriteWorkflowList.find(
                                  (fav) => item.id.id === fav.id.id
                                ))
                              }
                            />
                          );
                        })}
                    </FlipMove>
                  </>
                </div>
              </>
            )}

            {this.state.settings && (
              <SettingsContainer
                userMainObject={this.state.userMainObject}
                hotRefreshUserObject={(object) =>
                  this.hotRefreshUserObject(object)
                }
              />
            )}
          </div>
        </main>
      </>
    );
  }
}

export default withTranslation()(WorkflowLibrary);
