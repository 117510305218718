/**
 *
 * @file workflowlibraryAPI.js
 * @created_date Friday, Jan 12, 2024
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to workflow library authentication for the application, These functions are organized within the workflowlibraryAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial workflowAPI}
 * @module workflowAPI
 *
 **/

import { apiClient } from 'my-api-client-package';
import { getAuthAccessObject } from "../utils/tokenStorage";
import store from '../store/store';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const getWorkflowLibraryList = async (token = store.getState().token) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/workflowlibrary`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const getWorkflowLibraryById = async (id, token = store.getState().token) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/workflowlibrary/${id}/workflows`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const workflowlibraryAPI = {
  getWorkflowLibraryList,
  getWorkflowLibraryById,
};
export default workflowlibraryAPI;