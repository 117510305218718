/**
 * @file LandingPage.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Landingpage componenet is represents the landing page of a website. It imports an image file, a MainHeader component, and sets inline CSS styles for the page background.
 *
 *<br/>Breakdown of the code - {@tutorial LandingPage}
 * @module LandingPage
 *
 **/

import React from "react";
import backgroundImage from "../assets/img/normal_background_img_1.jpg";
import MainHeader from "./MainHeader";
import { useEffect } from "react";

const LandingPage = () => {
  // This statement is for inline css of this page.
  const mainPageStyles = {
    backgroundImage: `url(${backgroundImage})`,
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  return (
    <React.Fragment>
      <div className="container-sign-up-page" style={mainPageStyles}>
        <MainHeader />
      </div>
    </React.Fragment>
  );
};
export default LandingPage;
