import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import React, { useState, useEffect, useRef } from "react";
import { MiscFunctions } from 'helper-functions-package';

function AudioPlayer(props) {
  const recorderControls = useVoiceVisualizer({
    onEndAudioPlayback: () => {
      setAudioPlaying(false);
      setAudioDuration(true);
    },
  });

  const [audioDuration, setAudioDuration] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const { setPreloadedAudioBlob, togglePauseResume } = recorderControls;
  const [duration, setDuration] = useState("");
  const [formatTime, setFormatTime] = useState("");
  const autoPlayRef = useRef(null);
  let time = [];

  const audio = async () => {
    const response = await fetch(props.audioLink.audio);
    const data = await response.arrayBuffer();
    const blob = new Blob([data], { type: "audio/wav" });
    setPreloadedAudioBlob(blob);
  };

  useEffect(() => {
    audio();
  }, []);

  useEffect(() => {
    if(!MiscFunctions.isNull(props.comment) && !MiscFunctions.isUndefined(props.comment) && !MiscFunctions.isNull(props.comment.commentSettings) && props.comment.commentSettings.autoplay && recorderControls.audioRef.current!==null && recorderControls.audioRef.current?.src!==null){
      autoPlayRef.current.click();
      setAudioPlaying(true);
    }
  }, [recorderControls.audioRef.current?.src])

  useEffect(() => {
    audio();
  }, [props.audioLink.audio]);

  const handleFormatTime = () => {
    if (recorderControls.bufferFromRecordedBlob !== null) {
      const duration = parseInt(recorderControls.bufferFromRecordedBlob.duration);
      const minutes = Math.floor(duration / 60);
      const seconds = duration % 60;
      const format = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      setFormatTime(format);
    }
  };

  useEffect(() => {
    if(recorderControls.formattedDuration.includes("s")){
      time = recorderControls.formattedDuration.split("s");
    }else if(recorderControls.formattedDuration.includes("m")){
      time = recorderControls.formattedDuration.split("m");
    }
    setDuration(time[0]);
    handleFormatTime();
  }, [recorderControls])

  return (
    <>
      <span
        className={`${
          audioPlaying ? "icon_comment_audio_pause" : "icon_comment_audio_play"
        }`}
        ref={autoPlayRef}
        onClick={() => {
          setAudioPlaying(!audioPlaying);
          setAudioDuration(false);
          togglePauseResume();
        }}
      ></span>
      <VoiceVisualizer
        controls={recorderControls}
        isDefaultUIShown={false}
        isControlPanelShown={false}
        ref={recorderControls.audioRef}
        height={25}
        width={185}
        barWidth={2}
        rounded={2}
        fullscreen={false}
        mainBarColor="#4BAAAC"
        secondaryBarColor="#ADB5BD"
        defaultAudioWaveIconColor="#ADB5BD"
        defaultMicrophoneIconColor="#ADB5BD"
        isProgressIndicatorShown={true}
        isProgressIndicatorTimeShown={false}
        isProgressIndicatorOnHoverShown={true}
        isProgressIndicatorTimeOnHoverShown={true}
      />
      <div className="audio_duration">
        {formatTime}
      </div>
    </>
  );
}

export default AudioPlayer;
