/**
 *
 * @file Login.js
 * @created_date Thursday, December 15, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description the componenet is is a React function component that represents a login form in a web application. It allows users to enter their email and password, verify the reCAPTCHA, and submit the form to authenticate and log in.
 * <br/>Breakdown of the code - {@tutorial Login}
 * @module Login
 **/

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { MiscFunctions } from 'helper-functions-package';
import { Modal } from "flowbite-react";
import IndecatorUserProfile from "../assets/img/indicator_user_profile.png";
import ForgetPassword from "./ForgetPassword";
import { setAuthAccessObject } from "../utils/tokenStorage";
import authAPI from "../api/authAPI";
import userAPI from "../api/userAPI";
import ToastMessages from "../helpers/ToastMessages";
import { useTranslation } from "react-i18next";
import store from "../store/store";

function Login(props) {
  // This statement load reCaptchaKey from .env file (application rool dir).
  const reCaptchaKey = process.env.REACT_APP_SITE_KEY;

  // This hook enable/desable login button on reCaptcha verifiction.
  const [isLoginVerfied, setIsLoginVerified] = useState(false);

  // This hook is to set/get email input.
  const [loginEmail, setLoginEmail] = useState("");

  // This hook is to set/get passowrd input.
  const [loginPassword, setLoginPassword] = useState("");

  // This hook is to set/get recpacha value.
  const [captchaValue, setCaptchaValue] = useState("");

  // This hook is to set/get state of email validation.
  const [emailInvalidInput, setEmailInvalidInput] = useState(false);

  // This hook is to set/get state of password validation.
  const [passwordInvalidInput, setPasswordInvalidInput] = useState(false);

  // This hook is for enable/disable of spinner (Loading).
  const [spinner, setSpinner] = useState(false);

  // This method allow to navigate to any specified route.
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  // Below are predefined Default/Error/Success classes which is called on it's state for inputs.
  const inputClasses =
    "border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-transparent focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style";
  const errorClass =
    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 customize-input-style";
  const successClass =
    "border border-greeColorLight text-greeColorLight dark:text-greeColorLight placeholder-greeColorLight dark:placeholder-greeColorLight text-sm rounded-lg focus:ring-greeColorLight focus:border-greeColorLight block w-full p-2.5 dark:bg-gray-700 dark:border-greeColorLight customize-input-style";

  /**
   * @description This function handles email validation within the form. It utilizes React's useState hook to manage two state variables: emailInvalidLabel and userEmailIconColor.
   * The function is called when an event (e) occurs, it triggers by a blur event on the email input field.
   *
   * @function checkEmailValidation
   * @return {void}
   * */
  const [emailInvalidLabel, setEmailInvalidLabel] = useState("");
  const [userEmailIconColor, setUserEmailIconColor] = useState("");
  const recaptchaRef = useRef(null);

  const checkEmailValidation = (e) => {
    const email = MiscFunctions.emailValidation(loginEmail);
    if (!email) {
      setUserEmailIconColor("");
      e.target.className = errorClass;
      setEmailInvalidLabel("errorLabel");
      setEmailInvalidInput(true);
    } else {
      setUserEmailIconColor("normal_icon_email_confirm");
      e.target.className = successClass;
      setEmailInvalidLabel("");
      setEmailInvalidInput(false);
    }
  };

  /**
   * @description Initializes state variables (passwordInvalidLabel and userPasswordIconColor) using the useState hook to handle password validation and styling. The checkIfPasswordEmpty function is defined to check if the loginPassword value is empty. If it is empty, the function updates the state variables to reflect an invalid password state, including setting the passwordInvalidInput flag to true, applying an error class to the target element, and displaying an error label. On the other hand, if the password is not empty, the function updates the state variables to indicate a valid password state, resetting the passwordInvalidInput flag to false, clearing the passwordInvalidLabel, and applying a success class to the target element.
   *
   * @function checkIfPasswordEmpty
   * @return {void}
   * */
  const [passwordInvalidLabel, setPasswordInvalidLabel] = useState("");
  const [userPasswordIconColor, setUserPasswordIconColor] = useState("");
  const checkIfPasswordEmpty = (e) => {
    if (!loginPassword) {
      setUserPasswordIconColor("");
      setPasswordInvalidInput(true);
      setPasswordInvalidLabel("errorLabel");
      e.target.className = errorClass;
    } else {
      setUserPasswordIconColor("normal_icon_password_confirm");
      setPasswordInvalidInput(false);
      setPasswordInvalidLabel("");
      e.target.className = successClass;
    }
  };

  /**
   * @description This function is triggered when a login form is submitted. Inside the function, it performs the following steps: it sets the spinner state to true to indicate that the login process is in progress, prevents the default form submission behavior, calls the loginUser function from the authService module to perform the login, handles the response using .then and .catch methods, and performs different actions based on the response status. If the response status is not 200, it calls the loginFailure function and stops the spinner. If the response status is 200, it calls the getUserID function, handles its response, and performs further actions such as setting the access token and calling the loginSuccess function. Finally, it handles any errors that occur during the process by calling the loginFailure function and stopping the spinner.
   *
   * @function handleLoginSubmit
   * @return {void}
   * */
  const handleLoginSubmit = async (e) => {
    setSpinner(true);
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    const res = authAPI.loginUser(token, loginEmail, loginPassword);
    res
      .then(async (data) => {
        let token = await data.token;
        if (token.includes("Forbidden")) {
          ToastMessages.erorrMessage(t("messages.login_failure") + "!");
          setSpinner(false);
        } else {
          const ID = userAPI.getUserID(token, captchaValue, loginEmail);
          ID.then((userID) => {
            let newID = userID
            console.log("User-ID: " + newID);
            store.dispatch({ type: 'token/setToken', payload: token });
            setAuthAccessObject(
              JSON.stringify({
                loginStatus: true,
                token: token,
                recaptcha: captchaValue,
                ID: newID,
              })
            );

            ToastMessages.successMessage(t("messages.login_success") + ".");
            setSpinner(false);
            navigate("/workflow_library");
          });
        }
      })
      .catch((err) => {
        ToastMessages.erorrMessage(t("messages.login_failure") + "!");
        setSpinner(false);
      });
  };

  const [resetPasswordPopupVisible, setResetPasswordPopupVisible] =
    useState(false);
  const handleOnCloseResetPasswordModal = () => {
    setResetPasswordPopupVisible(false);
  };
  const handleResetPasswordModal = () => {
    props.setLoginPopupVisible(false);
    setResetPasswordPopupVisible(true);
  };
  const handleOnBackRegister = () => {
    setResetPasswordPopupVisible(false);
    props.setLoginPopupVisible(true);
  };

  useEffect(() => {
    recaptchaRef.current?.reset();
  }, [props]);

  useEffect(() => {
    if (loginEmail !== "" && loginPassword !== "") {
      setIsLoginVerified(true)
    } else {
      setIsLoginVerified(false)
    }
  }, [loginEmail, loginPassword])
  return (
    <>
      <form
        className="space-y-6 login-form"
        onSubmit={handleLoginSubmit}
        method="post"
      >
        <div className="input_group">
          <span
            className={userEmailIconColor + " normal_icon_email input_icon"}
          ></span>
          <div className="">
            <label
              htmlFor="email_login"
              className={
                emailInvalidLabel +
                " block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
              }
            >
              {t("login_register_page.your_email")}
            </label>
            <input
              type="email"
              name="email_login"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
              onFocus={(e) => {
                e.target.className = inputClasses;
                setUserEmailIconColor("normal_icon_email_select");
              }}
              onBlur={checkEmailValidation}
              id="email_login"
              className={inputClasses}
              required
            />
            {emailInvalidInput ? (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500 default-label-style">
                {t("messages.enter_valid_email")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="input_group">
          <span
            className={
              userPasswordIconColor + " normal_icon_password input_icon"
            }
          ></span>
          <div className="">
            <label
              htmlFor="password"
              className={
                passwordInvalidLabel +
                " block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
              }
            >
              {t("login_register_page.your_password")}
            </label>
            <input
              type="password"
              name="password_login"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
              onFocus={(e) => {
                e.target.className = inputClasses;
                setUserPasswordIconColor("normal_icon_password_select");
              }}
              onBlur={checkIfPasswordEmpty}
              id="password_login"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style"
              required
            />
            {passwordInvalidInput ? (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500 default-label-style">
                {t("messages.incorrect_passowrd")}
              </p>
            ) : (
              ""
            )}
            <p
              className=""
              id="control_text_forgot-password"
              onClick={handleResetPasswordModal}
            >
              {t("login_register_page.forgot_password")}
            </p>
          </div>
        </div>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={reCaptchaKey}
          hl={i18n.language}
          size="invisible"
          onChange={(value) => { setCaptchaValue(value) }}
          id="recaptcha-login-container"
          className="container_recaptcha_login"
          onErrored={(error) => {
            console.log("rec err: ", error);
          }}
          onExpired={() => {
            recaptchaRef.current.reset();
          }}
        />

        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="button_sign_in_up"
            onClick={(captchaValue) => handleLoginSubmit(captchaValue)}
            disabled={!isLoginVerfied}
          >
            {spinner ? (
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              ""
            )}{" "}
            {t("general.login")}
          </button>
        </div>
      </form>

      {/* Reset Password Popup. */}
      <Modal
        show={resetPasswordPopupVisible}
        className="register_main_modal"
        onClick={() => setResetPasswordPopupVisible(false)}
        onClose={() => {
          setResetPasswordPopupVisible(false);
        }}
      >
        <Modal.Body
          className="login_popup_body"
          style={{ overflow: "inherit", flex: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
            <div className="register-verify-block w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <img
                src={IndecatorUserProfile}
                alt=""
                className="user_role_image_verfiy"
              />
              <span
                className="button_modal_close"
                onClick={handleOnCloseResetPasswordModal}
              ></span>
              <span
                className="button_modal_back"
                onClick={handleOnBackRegister}
              ></span>

              <ForgetPassword reCaptchaReset={true} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;
