import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: null, // Initial user value
    reducers: {
        setUser: (state, action) => action.payload, // Update user
        clearUser: () => null, // Clear user
    },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
