/**
 *
 * @file userAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to user authentication for the application, These functions are organized within the userAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial userAPI}
 * @module userAPI
 *
 **/

import store from "../store/store";
import { getAuthAccessObject } from "../utils/tokenStorage";
import { apiClient } from 'my-api-client-package';
const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * @description This function sends a GET request to the /user/id/:email endpoint to retrieve the user's ID. It includes the access token and reCAPTCHA value in the headers. It returns the user's ID if the request is successful.
 * <br />Here you can find some examples: - {@tutorial userAPI}
 * @function getUserID
 * @return {void}
 * */
const getUserID = async (token, captchaValue, email) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${token}`,
      recaptcha: captchaValue,
    };
    return apiClient
      .get(API_BASE_URL, `/user/id/${email}`, customHeaders)
      .then(async (response) => {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await resp.message;
          return data;
        }
      });
  } catch (err) {
    return err;
  }
};


/**
 * @description This function sends a PUT request to the /user endpoint to change it's content in the DB. It includes the access token and reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - 
 * @function updateUserObject
 * @return {void}
 * */
const updateUserObject = async (data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .put(API_BASE_URL, `/user`, data, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  } catch (e) {
    console.error("Error: ", e);
    return e
  }
};

/**
 * @description This function sends a GET request to the /user endpoint to retrieve the user object. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * <br />Here you can find some examples: - {@tutorial userAPI}
 * @function getUserObject
 * @return {void}
 * */
const getUserObject = async (token = store.getState().token) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/user`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /user/:id/company/list endpoint to retrieve a list of users in a company. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * <br />Here you can find some examples: - {@tutorial userAPI}
 * @function getCompanyUsers
 * @return {void}
 * */
const getCompanyUsers = async () => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/user/company/list`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description des
 * @function favouriteWorkflowByID
 * @return {void}
 * */
const favouriteWorkflowByID = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/user/favourite/${id}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        console.log(resp)
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description
 * @function getFavoriteWorkflowByID
 * @return {void}
 * */
const getFavoriteWorkflowByID = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/user/favourite/${id}/workflow`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        console.log(resp)
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const deleteFavouriteWorkflowByID = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .delete(API_BASE_URL, `/user/favourite/${id}`, customHeaders)
      .then(async function (response) {
        console.log(await response);
        return await response;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description  This function sends a PUT request to the /user/change/password endpoint to change the password. It includes the access token in the headers.
 * <br />Here you can find some examples: 
 * @function const changeUserPassword = async (id) => {

 * @return {void}
 * */
const changeUserPassword = async (data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };

    return await apiClient
      .put(API_BASE_URL, `/user/change/password`, data, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  } catch (e) {
    console.error("Error: ", e);
    return e;
  }
};

/**
 * @description  This function sends a DELETE request to the /user endpoint to delete the user. It includes the access token in the headers.
 * <br />Here you can find some examples: 

 * @return {void}
 * */
const deleteUserWithID = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };

    return await apiClient
      .delete(API_BASE_URL, `/user`, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  } catch (e) {
    console.error("Error: ", e);
    return e;
  }
};

const setPreference = async (value) => {
  const bodyData = {
    "preferences": [value]
  };
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .post(API_BASE_URL, `/user/preference`, bodyData, customHeaders)
      .then(async (data) => {
        let resJson = await data.json();
        return resJson;
      });
  } catch (err) {
    return err;
  }
}

/**
 * @description This function sends a GET request to the /user endpoint to retrieve the user object. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * <br />Here you can find some examples: - {@tutorial userAPI}
 * @function getUserObject
 * @return {void}
 * */
const getUserFavoritedWorkflows = async () => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/workflows/user/favourite`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const userAPI = {
  getUserID,
  getUserObject,
  updateUserObject,
  getCompanyUsers,
  favouriteWorkflowByID,
  getFavoriteWorkflowByID,
  deleteFavouriteWorkflowByID,
  changeUserPassword,
  deleteUserWithID,
  setPreference,
  getUserFavoritedWorkflows
};

export default userAPI;