import { createSlice } from '@reduxjs/toolkit';
import { getAuthAccessObject, removeAuthAccessObject, setAuthAccessObject } from '../utils/tokenStorage';

const initialToken = (getAuthAccessObject() && getAuthAccessObject().token) || null;

const tokenSlice = createSlice({
  name: 'token',
  initialState: initialToken,
  reducers: {
    setToken: (state, action) => {
      const token = action.payload;
      (getAuthAccessObject() && token) && setAuthAccessObject(
        JSON.stringify(Object.assign(getAuthAccessObject(), { token: token }))
      );
      return token;
    },
    clearToken: () => {
      removeAuthAccessObject()
      return null;
    },
  },
});

export const { setToken, clearToken } = tokenSlice.actions;

export default tokenSlice.reducer;